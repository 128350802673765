exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-access-tsx": () => import("./../../../src/pages/access.tsx" /* webpackChunkName: "component---src-pages-access-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-inside-tsx": () => import("./../../../src/pages/inside.tsx" /* webpackChunkName: "component---src-pages-inside-tsx" */),
  "component---src-pages-menu-tsx": () => import("./../../../src/pages/menu.tsx" /* webpackChunkName: "component---src-pages-menu-tsx" */),
  "component---src-pages-otoku-tsx": () => import("./../../../src/pages/otoku.tsx" /* webpackChunkName: "component---src-pages-otoku-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-pages-reserve-tsx": () => import("./../../../src/pages/reserve.tsx" /* webpackChunkName: "component---src-pages-reserve-tsx" */),
  "component---src-pages-waiting-tsx": () => import("./../../../src/pages/waiting.tsx" /* webpackChunkName: "component---src-pages-waiting-tsx" */)
}

